/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings': {
    width: 50,
    height: 50,
    viewBox: '0 0 50 50',
    data: '<path pid="0" d="M47.16 21.221l-5.91-.966a16.933 16.933 0 00-1.411-3.405l3.45-4.917a1.001 1.001 0 00-.112-1.282l-3.889-3.887a1 1 0 00-1.291-.104l-4.843 3.481a16.774 16.774 0 00-3.432-1.427l-1.031-5.886A1 1 0 0027.706 2h-5.5a1 1 0 00-.987.839l-.956 5.854c-1.2.345-2.352.818-3.437 1.412l-4.83-3.45a1 1 0 00-1.289.106L6.82 10.648a.998.998 0 00-.112 1.28l3.399 4.863a16.937 16.937 0 00-1.438 3.46l-5.831.971a1 1 0 00-.836.986v5.5a1 1 0 00.825.985l5.831 1.034a16.853 16.853 0 001.438 3.46L6.655 38c-.284.397-.239.942.106 1.289l3.888 3.891a1 1 0 001.281.112l4.87-3.411a16.871 16.871 0 003.445 1.424l.976 5.861a.997.997 0 00.985.834h5.5c.485 0 .9-.348.984-.825l1.045-5.89a16.816 16.816 0 003.43-1.435l4.905 3.441a.999.999 0 001.282-.111l3.888-3.891a1 1 0 00.104-1.292l-3.498-4.857a16.74 16.74 0 001.407-3.408l5.918-1.039a1 1 0 00.827-.985v-5.5a.998.998 0 00-.838-.987zM25 32a7 7 0 110-14 7 7 0 010 14z"/>'
  }
})
